/*
 * Copyright © 2021 Outreach Trading & Supply
 */

@font-face {
  font-family: 'commando';
  src: url('commando.b5fecd88.woff2') format('woff2'),
    url('commando.04675da3.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  color: black;
  background: #EEEEEE;
  font-size: 12pt;
  margin: 0;
  padding: 0;
  font-family: Helvetica, sans-serif;
}

body {
  height: auto;
  max-width: 80ex;
  margin: 2ex auto;
  padding: 1px 2ex;
  border-radius: 3px;

  background: white;
}

header, footer {
  user-select: none;
}

/** Tables */

table {
  width: 100%;
}

th, td {
 /** Override picnic's over-sized padding */
 padding-left: 0.6em;
 padding-right: 0.6em;
}

th.numeric,
td.numeric {
  width: 0;
  text-align: end;
  white-space: nowrap;
}

/** Forms */

form *:not(input) {
  user-select: none;
}

form *.submit {
  text-align: end;
}


tr[data-v-feb824] {
  white-space: nowrap;
}
tr[data-v-feb824]:hover {
  background: rgba(0, 116, 217, 0.2);
}
tr[data-v-feb824]:active {
  background: rgba(0, 116, 217, 0.1);
}
span.pill[data-v-feb824] {
  display: inline-block;
  margin: 0 2px;
  border: 1px solid silver;
  border-radius: 1.5em;
  padding: 2px 8px;

  font-size: 0.8em;
}
span.pill[data-v-feb824]:hover {
  border: 1px solid silver;
}



tbody.search td[data-v-463392] {
  padding: 0.6em 0 0.3em 0;
}



table[data-v-52df94] {
  margin-top: 0.6em;
}
form *.submit[data-v-52df94] {
  padding: 0.6em 0 0.3em 0;
}


header[data-v-5b5e1c] {
  border-bottom: 1px solid silver;
  padding: 2ex 0;
}
header > *[data-v-5b5e1c] {
  font-family: commando, Courier, sans-serif;
  margin: 0;
  padding: 0;
}
header > h1[data-v-5b5e1c] {
  font-size: 2.4rem;
  line-height: 2.4rem;
  margin-top: 1rem;
}
header > img[data-v-5b5e1c] {
  height: 4.8rem;
  margin: 0 1rem;
  float: left;
}
footer[data-v-5b5e1c] {
  color: silver;
  font-size: 0.8em;
}

/*# sourceMappingURL=index.89c9f69a.css.map */
