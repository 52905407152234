/*
 * Copyright © 2021 Outreach Trading & Supply
 */

@font-face {
  font-family: 'commando';
  src: url('../chrome/commando.woff2') format('woff2'),
    url('../chrome/commando.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  color: black;
  background: #EEEEEE;
  font-size: 12pt;
  margin: 0;
  padding: 0;
  font-family: Helvetica, sans-serif;
}

body {
  height: auto;
  max-width: 80ex;
  margin: 2ex auto;
  padding: 1px 2ex;
  border-radius: 3px;

  background: white;
}

header, footer {
  user-select: none;
}

/** Tables */

table {
  width: 100%;
}

th, td {
 /** Override picnic's over-sized padding */
 padding-left: 0.6em;
 padding-right: 0.6em;
}

th.numeric,
td.numeric {
  width: 0;
  text-align: end;
  white-space: nowrap;
}

/** Forms */

form *:not(input) {
  user-select: none;
}

form *.submit {
  text-align: end;
}
